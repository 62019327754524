/* eslint-disable react/prop-types */
import React from 'react'
import {
  array, number, oneOfType, shape, string,
} from 'prop-types'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import omit from 'lodash/omit'
import Link from 'components/links/Link'
import Text from 'components/text/Text'
import theme from 'styles/theme'

const BaseText = ({
  children,
  ...rest
}) => (
  <Text
    fontFamily={theme.fonts.RocGroteskCondensed}
    fontWeight="600"
    {...rest}
  >
    {children}
  </Text>
)

// eslint-disable-next-line react/prop-types
const BoldText = ({
  children,
  ...rest
}) => (
  <Text
    as="b"
    fontFamily={theme.fonts.default}
    fontWeight="800"
    {...rest}
  >
    {children}
  </Text>
)

// eslint-disable-next-line react/prop-types
const ItalicText = ({
  children,
  ...rest
}) => (
  <Text
    as="i"
    fontFamily={theme.fonts.RocGroteskExtraWide}
    fontWeight="800"
    {...rest}
  >
    {children}
  </Text>
)

/* eslint-disable react/display-name */
const Headline = ({
  as: asProp,
  document,
  ...rest
}) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => (
        <BoldText {...omit(rest, ['document'])}>
          {text}
        </BoldText>
      ),
      [MARKS.ITALIC]: (text) => (
        <ItalicText {...omit(rest, ['document'])}>
          {text}
        </ItalicText>
      ),
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <BaseText as={asProp} {...omit(rest, ['document'])}>
          {children}
        </BaseText>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <>
          <BaseText as={asProp} display="block" {...omit(rest, ['document'])}>
            {children}
          </BaseText>
        </>
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <Link href={data?.uri}>
          <BaseText {...omit(rest, ['document'])}>
            {children}
          </BaseText>
        </Link>
      ),
    },
  }

  return documentToReactComponents(document, options)
}
/* eslint-enable react/display-name */

Headline.propTypes = {
  as: string,
  color: oneOfType([string, number, array]),
  document: shape(),
  fontSize: oneOfType([string, number, array]),
  textAlign: string,
  textDecoration: string,
  textTransform: string,
}

Headline.defaultProps = {
  as: 'span',
  color: 'white',
  textTransform: 'uppercase',
}

export default Headline
