import React from 'react'
import { useContentful } from 'react-contentful'
import { fieldsParser } from 'contentful-parsers'
import { isClient } from 'next-utils'
import useIsMobile from 'components/layout/useIsMobile'
import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'

const Header = () => {
  const { data } = useContentful({
    contentType: 'headerModule',
    parser: (parserData) => (parserData && fieldsParser(parserData)) || null,
    id: '7sSMBRLI4iI1e7VEaW0t9d',
    skip: !isClient(),
  })
  const isMobile = useIsMobile()

  const { navigationItems } = data || { navigationItems: [] }

  return isMobile
    ? <NavMobile navigationItems={navigationItems} />
    : <NavDesktop navigationItems={navigationItems} />
}

export default Header
