import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { Text } from 'components/text'
import { string } from 'prop-types'
import theme from 'styles/theme'
import { Back } from 'components/icons'

const StyledLink = styled.a` 
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background: none;
  color: ${({ color }) => color};
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  span {
    text-transform: uppercase;
    position: relative;
    top: 1px;
  }

  svg {
    margin-right: 10px;
    height: 16px;
    width: auto;

    @media (min-width: ${theme.breakpoints.lg}) {
      height: 19px;
      width: auto;
    }
  }
`

const BackButton = ({ href, label, color }) => (
  <Link href={href} passHref>
    <StyledLink color={color}>
      <Back />
      <Text
        as="span"
        color={color}
        fontFamily={theme.fonts.RocGrotesk}
        fontSize={['16px', '16px', '18px']}
        fontWeight="500"
      >
        {label}
      </Text>
    </StyledLink>
  </Link>
)

BackButton.propTypes = {
  href: string.isRequired,
  label: string,
  color: string,
}

BackButton.defaultProps = {
  label: 'BACK',
  color: 'white',
}

export default BackButton
