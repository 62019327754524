import React from 'react'
import styled from 'styled-components'

const StyledImage = styled.img`
  display: block;
  width: 100%;
  max-width: 215px;
`

const Halo = () => (
  <StyledImage src="/halo.png" />
)

export default Halo
