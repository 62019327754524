import styled from 'styled-components'
import {
  layout, space, flexbox, position,
} from 'styled-system'

const FlexContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;
    padding-left: ${parseInt(theme.grid.margin.sm, 10) - 0.5 * parseInt(theme.grid.gutters.sm, 10)}px;
    padding-right: ${parseInt(theme.grid.margin.sm, 10) - 0.5 * parseInt(theme.grid.gutters.sm, 10)}px;
    z-index: ${theme.zIndices.content};

    @media (min-width: ${theme.breakpoints.md}) {
      padding-left: ${parseInt(theme.grid.margin.md, 10) - 0.5 * parseInt(theme.grid.gutters.md, 10)}px;
      padding-right: ${parseInt(theme.grid.margin.md, 10) - 0.5 * parseInt(theme.grid.gutters.md, 10)}px;
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      padding-left: ${parseInt(theme.grid.margin.lg, 10) - 0.5 * parseInt(theme.grid.gutters.lg, 10)}px;
      padding-right: ${parseInt(theme.grid.margin.lg, 10) - 0.5 * parseInt(theme.grid.gutters.lg, 10)}px;
    }
  `}
  ${layout}
  ${flexbox}
  ${space}
  ${position}
`

export default FlexContainer
