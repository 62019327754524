import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'

const StyledLink = styled.a`
  text-decoration: none;
  padding-right: 30px;

  svg {
    height: ${({ size }) => size};
    width: auto;
  }
`

const AppLink = ({ href, children, size }) => (
  <StyledLink href={href} size={size}>
    {children}
  </StyledLink>
)

AppLink.propTypes = {
  href: string.isRequired,
  children: node.isRequired,
  size: string,
}

AppLink.defaultProps = {
  size: '40px',
}

export default AppLink
