import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'
import {
  background,
  color,
  minWidth,
  maxWidth,
  width,
  height,
  space,
  textAlign,
  layout,
  flexbox,
  position,
  system,
} from 'styled-system'
import NextLink from 'next/link'

const BaseTag = styled('a')`
  ${system({
    border: true,
    textDecoration: true,
  })}
  ${background};
  ${color};
  ${position};
  ${minWidth};
  ${maxWidth};
  ${width};
  ${height};
  ${textAlign};
  ${space};
  ${layout};
  ${flexbox};
`

const Link = ({ children, href, ...rest }) => {
  if (href?.startsWith(process.env.NEXT_PUBLIC_BASE_URL)) {
    const localUrl = href.replace(process.env.NEXT_PUBLIC_BASE_URL, '')

    return (
      <NextLink href={localUrl} passHref>
        <BaseTag {...rest}>
          {children}
        </BaseTag>
      </NextLink>
    )
  }

  return (
    <BaseTag href={href} {...rest}>
      {children}
    </BaseTag>
  )
}

Link.propTypes = {
  children: node,
  href: string,
}

Link.defaultProps = {
  children: null,
  href: null,
}

export default Link
