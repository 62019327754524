import React, { useState } from 'react'
import { arrayOf, shape } from 'prop-types'
import styled from 'styled-components'
import { Box } from 'components/layout'
import { Hamburger, OrderButton } from 'components/buttons'
import Link from 'next/link'
import Logo from 'components/icons/Logo'
import NavLink from './NavLink'

const Spacer = styled.div`
  position: relative;
  display: block;
  height: 70px;
  background: black;
`
const StickyBackground = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: ${({ theme }) => theme.zIndices.nav};
`

const StyledLogoLink = styled.a`
  position: relative;
  text-decoration: none;
  outline: none;
  display: block;
  height: 25px;
  width: auto;
  
  svg {
    position: relative;
    height: 100%;
    width: auto;
    fill: currentColor;
    color: inherit;
    transition: height 0.3s ease-out, color 0.2s ease-out;
  }
`

const StickyHeader = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding-left: 23px;
  height: 70px;
  z-index: ${({ theme }) => theme.zIndices.nav + 2};

  &.open {
    .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
      background-color: black;
    }
  }

  &.closed {
    .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
      background-color: white;
    }
  }

`

const MobileMenu = styled.div`
  background: white;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 28px;
  background: white;
  z-index: ${({ theme }) => theme.zIndices.nav + 1};
  transition: all 0.2s ease-in-out 0s;
  padding-top: 100px;
  flex-direction: column;
  line-height: 26px;

  a {
    font-weight: 600;
  }

  &.open {
    opacity: 1;
    visibility: visible;
  }

  &.closed {
    opacity: 0;
    visibility: hidden;
  }
`

const NavMobile = ({ navigationItems }) => {
  const [open, setOpen] = useState(false)

  const onClick = () => {
    setOpen(false)
  }

  return (
    <div>
      <Spacer />
      <StickyBackground />
      <OrderButton style={{ top: 70 }} />
      <MobileMenu className={open ? 'open' : 'closed'}>
        {navigationItems.map((link) => (
          <NavLink key={link.id} onClick={onClick} href={link.navigationItemLink}>
            {link.navigationItemName}
          </NavLink>
        ))}
      </MobileMenu>
      <StickyHeader className={open ? 'open' : 'closed'}>
        <Hamburger className="hamburger" open={open} onClick={() => setOpen(!open)} />
        <Box width="15px" />
        <Link href="/" passHref>
          <StyledLogoLink style={{ color: open ? 'black' : 'white' }}>
            <Logo fill="currentColor" />
          </StyledLogoLink>
        </Link>
      </StickyHeader>
    </div>
  )
}

NavMobile.propTypes = {
  navigationItems: arrayOf(shape()),
}

NavMobile.defaultProps = {
  navigationItems: [],
}

export default NavMobile
