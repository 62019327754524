import styled from 'styled-components'
import {
  space, flexbox, grid, position, system,
} from 'styled-system'

const Container = styled.div`
  ${({ theme }) => `
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    box-sizing: border-box;
    padding-left: ${theme.grid.margin.sm};
    padding-left: ${theme.grid.margin.sm};
    padding-right: ${theme.grid.margin.sm};
    grid-gap: ${theme.grid.gutters.sm};
    position: relative;
    z-index: ${theme.zIndices.content};

    @media (min-width: ${theme.breakpoints.md}) {
      padding-left: ${theme.grid.margin.md};
      padding-right: ${theme.grid.margin.md};
      grid-gap: ${theme.grid.gutters.md};
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      padding-left: ${theme.grid.margin.lg};
      padding-right: ${theme.grid.margin.lg};
      grid-gap: ${theme.grid.gutters.lg};
    }
  `}
  ${grid}
  ${flexbox}
  ${space}
  ${position}
  ${system({
    overflowX: true,
  })}
`

export default Container
