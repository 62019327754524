import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { Box, Container, Grid } from 'components/layout'
import Text from 'components/text/Text'
import Apple from 'components/icons/Apple'
import Android from 'components/icons/Android'
import Instagram from 'components/icons/Instagram'
import Halo from './Halo'
import AppLink from './AppLink'
import FooterLink from './FooterLink'

const FooterDesktop = ({ navigationItems }) => {
  const midIndex = Math.ceil(navigationItems.length / 2)
  const leftLinks = navigationItems.slice(0, midIndex)
  const rightLinks = navigationItems.slice(midIndex)

  return (
    <Box background="black" py="60px">
      <Container>
        <Grid col="1/6">
          <Halo />
          <Text
            pt="55px"
            color="white"
            fontSize="18px"
            fontWeight={400}
            lineHeight="26px"
          >
            &copy; 2021 BEATNIC – All Rights Reserved
          </Text>
          <Text
            color="silver"
            fontSize="18px"
            fontWeight={400}
            lineHeight="26px"
          >
            Branding By Pearlfisher
            <br />
            Design &amp; Site By Heat Waves
          </Text>
        </Grid>
        <Grid col="6/9">
          <Text
            color="white"
            fontSize="18px"
            fontWeight={400}
            lineHeight="26px"
          >
            DOWNLOAD THE BEATNIC REWARDS APP
          </Text>
          <Box pt="20px">
            <AppLink href="https://apps.apple.com/us/app/beatnic/id1473075569">
              <Apple />
            </AppLink>
            <AppLink href="https://play.google.com/store/apps/details?id=com.bychefchloe.bychloe.levelup.whitelabel.app&hl=en_US&gl=US">
              <Android />
            </AppLink>
          </Box>
          <Box pt="68px">
            <AppLink href="https://www.instagram.com/eatbeatnic/" size="24px">
              <Instagram />
            </AppLink>
            <Box pt="20px">
              <Text
                as="a"
                color="white"
                fontSize="18px"
                fontWeight={400}
                lineHeight="26px"
                href="mailto:hello@eatbeatnic.com"
                style={{ textDecoration: 'underline' }}
              >
                hello@eatbeatnic.com
              </Text>
            </Box>
          </Box>
        </Grid>
        <Grid col="9/11">
          {leftLinks.map((link) => (
            <FooterLink key={link.id} href={link.navigationItemLink}>
              {link.navigationItemName}
            </FooterLink>
          ))}
        </Grid>
        <Grid col="11/13">
          {rightLinks.map((link) => (
            <FooterLink key={link.id} href={link.navigationItemLink}>
              {link.navigationItemName}
            </FooterLink>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

FooterDesktop.propTypes = {
  navigationItems: arrayOf(shape()),
}

FooterDesktop.defaultProps = {
  navigationItems: [],
}

export default FooterDesktop
