import { node } from 'prop-types'
import React from 'react'

const PageContainer = ({ children }) => (
  <div>
    {children}
  </div>
)

PageContainer.propTypes = {
  children: node.isRequired,
}

export default PageContainer
