import { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { node, string } from 'prop-types'

const Portal = ({ selector, children }) => {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)
  const selectorPrefixed = `#${selector.replace(/^#/, '')}`

  useEffect(() => {
    ref.current = document.querySelector(selectorPrefixed)

    if (!ref.current) {
      const div = document.createElement('div')
      div.setAttribute('id', selector)
      document.body.appendChild(div)
      ref.current = div
    }

    setMounted(true)
  }, [selector, selectorPrefixed])

  return mounted ? ReactDOM.createPortal(children, ref.current) : null
}

Portal.propTypes = {
  selector: string,
  children: node,
}

Portal.defaultProps = {
  selector: '__ROOT_PORTAL__',
  children: null,
}

export default Portal
