import React from 'react'
import styled from 'styled-components'

import {
  background,
  color,
  minWidth,
  maxWidth,
  width,
  height,
  space,
  textAlign,
  layout,
  flexbox,
  position,
  shadow,
  border,
} from 'styled-system'

const StyledBox = styled.div`
  box-sizing: border-box;
  transition: padding 0.2s ease-out;
  ${background};
  ${color};
  ${position};
  ${minWidth};
  ${maxWidth};
  ${width};
  ${height};
  ${textAlign};
  ${space};
  ${layout};
  ${flexbox};
  ${shadow};
  ${border};
`;

const Box = React.forwardRef((props, ref) => (
  <StyledBox ref={ref} {...props} />
))

Box.displayName = 'Box'

export default Box
