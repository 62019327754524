import React from 'react'

const Apple = () => (
  <svg width="42" height="48" viewBox="0 0 42 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.7261 9.11507C27.9378 7.90398 28.832 6.57748 29.409 5.13506C29.9668 3.69264 30.2457 2.38535 30.2457 1.21171C30.2457 1.13533 30.2408 1.03808 30.2312 0.923205C30.221 0.808328 30.2168 0.712743 30.2168 0.634703C30.1976 0.577003 30.1735 0.471204 30.1447 0.316784C30.1158 0.163499 30.0918 0.0577003 30.0726 0C26.6877 0.788512 24.2841 2.21173 22.8611 4.26956C21.4186 6.34616 20.6685 8.81749 20.6108 11.683C21.8988 11.5671 22.8989 11.4048 23.6111 11.1932C24.6496 10.846 25.6876 10.1541 26.7261 9.11507Z" fill="white" />
    <path d="M36.5341 31.4131C35.2071 29.5102 34.5436 27.3658 34.5436 24.98C34.5436 22.8072 35.1681 20.8172 36.4187 19.0088C37.0919 18.0285 38.1881 16.8938 39.7074 15.6047C38.7074 14.3744 37.7073 13.4133 36.7072 12.7199C34.8993 11.4713 32.8511 10.8447 30.563 10.8447C29.1975 10.8447 27.5339 11.1728 25.5722 11.8262C23.6874 12.4806 22.3124 12.8064 21.4474 12.8064C20.7936 12.8064 19.4666 12.5179 17.4663 11.941C15.447 11.364 13.7455 11.0755 12.3607 11.0755C9.05274 11.0755 6.3218 12.4602 4.1683 15.2302C1.99506 18.0381 0.908447 21.634 0.908447 26.0184C0.908447 30.6722 2.32197 35.5085 5.1492 40.5287C8.01431 45.5093 10.9087 47.9993 13.832 47.9993C14.8128 47.9993 16.0816 47.6724 17.6394 47.0191C19.1973 46.3843 20.5622 46.0671 21.7354 46.0671C22.9855 46.0671 24.437 46.3748 26.0914 46.9897C27.841 47.6051 29.1874 47.9123 30.1298 47.9123C32.5909 47.9123 35.0623 46.028 37.5432 42.2591C39.1588 39.8556 40.3416 37.451 41.0916 35.0475C39.3801 34.5288 37.8612 33.3171 36.5341 31.4131Z" fill="white" />
  </svg>
)

export default Apple
