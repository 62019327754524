import React from 'react'
import { node, string } from 'prop-types'
import Link from 'next/link'
import styled from 'styled-components'

const StyledLink = styled.a`
  display: block;
  text-decoration: none;
  outline: none;
  color: white;
  font-family: ${({ theme }) => theme.fonts.RocGrotesk};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  padding-bottom: 10px;
  line-height: 23px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 24px;
    padding-bottom: 20px;
    line-height: 28px;
  }

`

const FooterLink = ({ href, children, ...other }) => {
  if (href.startsWith('/')) {
    return (
      <Link href={href} passHref>
        <StyledLink {...other}>
          {children}
        </StyledLink>
      </Link>
    )
  }
  return (
    <StyledLink href={href} {...other}>
      {children}
    </StyledLink>
  )
}

FooterLink.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
}

export default FooterLink
