import React from 'react'
import getConfig from 'next/config'
import styled from 'styled-components'
import Text from 'components/text/Text'

const { publicRuntimeConfig } = getConfig()
const { ORDER_URL } = publicRuntimeConfig

const StyledButton = styled.a`
  position: fixed;
  top: 40px;
  right: -105px;
  background: ${({ theme }) => theme.colors.havelockblue};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 40px;
  transform: rotate(90deg);
  transform-origin: top left;
  text-align: center;
  text-decoration: none;
  outline: none;
  z-index: ${({ theme }) => theme.zIndices.nav};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 160px;
    height: 70px;
    top: 55px;
    right: -160px;
  }

`

const OrderButton = (props) => (
  <StyledButton href={`${ORDER_URL}/order-type`} {...props}>
    <Text
      color="white"
      textAlign="center"
      fontWeight="700"
      pt="2px"
      fontSize={['14px', '14px', '20px']}
    >
      ORDER NOW
    </Text>
  </StyledButton>
)

export default OrderButton
