import React from 'react'
import Link from 'next/link'
import { string } from 'prop-types'
import { Text } from 'components/text'
import styled from 'styled-components'
import theme from 'styles/theme'

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.havelockblue};
  text-decoration: none;
  justify-content: center;
  padding: 10px 20px;

  @media (min-width: ${theme.breakpoints.lg}) {
    display: inline-flex;
  }
`

const OrderItemDetail = ({ href, ...rest }) => (
  <Link href={href} passHref>
    <StyledLink {...rest}>
      <Text
        as="span"
        color="white"
        fontSize={['14px', '14px', '20px']}
        lineHeight="20px"
        fontWeight={600}
        textTransform="uppercase"
      >
        ORDER NOW
      </Text>
    </StyledLink>
  </Link>
)

OrderItemDetail.propTypes = {
  href: string,
}

OrderItemDetail.defaultProps = {
  href: null,
}

export default OrderItemDetail
