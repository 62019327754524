import React from 'react';
import { string, bool, func } from 'prop-types';
import styled from 'styled-components';

const HAMBURGER_CONFIG = {
  hitbox: 40,
  spacing: 5,
  height: 3,
  width: 30,
  color: 'white',
}

const HamburgerButton = styled.button`
  position: relative;
  display: inline-block;
  width: ${HAMBURGER_CONFIG.hitbox}px;
  height: ${HAMBURGER_CONFIG.hitbox}px;
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  -webkit-tap-highlight-color:rgba(0,0,0,0);

  .hamburger-box {
    width: ${HAMBURGER_CONFIG.width}px;
    height: ${HAMBURGER_CONFIG.height * 3 + HAMBURGER_CONFIG.spacing * 2}px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: ${HAMBURGER_CONFIG.height / -2}px;

    &,
    &::before,
    &::after {
      width: ${HAMBURGER_CONFIG.width}px;
      height: ${HAMBURGER_CONFIG.height}px;
      background-color: ${HAMBURGER_CONFIG.color};
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      top: ${(HAMBURGER_CONFIG.spacing + HAMBURGER_CONFIG.height) * -1}px;
    }

    &::after {
      bottom: ${(HAMBURGER_CONFIG.spacing + HAMBURGER_CONFIG.height) * -1}px;
    }
  }

  // ANIMATION - SPIN
  .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.25s ease-in,
                  opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.25s ease-in,
                  transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.open {
    .hamburger-inner {
      transform: rotate(135deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
`;

const Hamburger = ({ className, open, onClick }) => (
  <HamburgerButton
    className={`${className} ${open ? 'open' : 'closed'}`}
    onClick={onClick}
    type="button"
  >
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </HamburgerButton>
);

Hamburger.propTypes = {
  className: string,
  open: bool,
  onClick: func,
};

Hamburger.defaultProps = {
  className: '',
  open: false,
  onClick: () => {},
};

export default Hamburger;
