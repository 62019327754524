import {
  string, number, oneOfType, array,
} from 'prop-types'
import styled from 'styled-components'
import {
  color,
  layout,
  space,
  system,
  typography,
} from 'styled-system'

import theme from 'styles/theme'

const Text = styled('p').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => ![
    'color',
    'fontFamily',
    'fontSize',
    'fontStyle',
    'fontWeight',
    'lineHeight',
    'letterSpacing',
    'textAlign',
  ].includes(prop) && defaultValidatorFn(prop),
})`
  ${system({
    textDecoration: true,
    textTransform: true,
    whiteSpace: true,
  })}
  ${color}
  ${layout}
  ${space}
  ${typography}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
`

Text.propTypes = {
  fontFamily: string,
  fontSize: oneOfType([string, number, array]),
  fontWeight: oneOfType([string, number, array]),
  lineHeight: oneOfType([string, number, array]),
  letterSpacing: oneOfType([string, number, array]),
  textAlign: string,
  textTransform: string,
  fontStyle: string,
}

Text.defaultProps = {
  fontFamily: theme.fonts.default,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1,
  letterSpacing: 0,
  textAlign: 'left',
  textTransform: 'none',
  fontStyle: 'normal',
}

export default Text
