import { useState, useEffect } from 'react'
import theme from 'styles/theme'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  const onResize = () => {
    const width = theme.breakpoints.lg.replace('px', '') * 1
    const newValue = window.innerWidth < width
    setIsMobile(newValue)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isMobile
}

export default useIsMobile
