import React, { useEffect, useState } from 'react'
import { arrayOf, shape } from 'prop-types'
import styled from 'styled-components'
import { Container, Grid } from 'components/layout'
import OrderButton from 'components/buttons/OrderButton'
import Link from 'next/link'
import Logo from 'components/icons/Logo'
import NavLink from './NavLink'

const Spacer = styled.div`
  position: relative;
  display: block;
  height: 160px;
  background: black;
`
const StickyHeader = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  background: black;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  z-index: ${({ theme }) => theme.zIndices.nav};
`
const StyledLogoLink = styled.a`
  position: relative;
  text-decoration: none;
  outline: none;
  display: block;
  height: 80px;
  width: auto;
  color: white;
  
  svg {
    position: relative;
    height: 100%;
    width: auto;
    fill: currentColor;
    color: inherit;
    transition: transform 0.3s ease-out;
  }

  &.scrolled {
    svg {
      transform: scale(0.35)
    }
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`

const NavDesktop = ({ navigationItems }) => {
  const [scrolled, setScrolled] = useState(false)
  const [height, setHeight] = useState(160)

  const midIndex = Math.ceil(navigationItems.length / 2)
  const leftLinks = navigationItems.slice(0, midIndex)
  const rightLinks = navigationItems.slice(midIndex)

  const onScroll = () => {
    const max = 160
    const min = 100
    const diff = max - min
    const newPerc = Math.max(0, Math.min(1, (diff - window.scrollY) / diff))
    const newHeight = min + diff * newPerc
    setHeight(newHeight)
    setScrolled(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    onScroll()
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <div>
      <Spacer />
      <StickyHeader height={height}>
        <OrderButton style={{ top: height }} />
        <Container>
          <Grid col="1/13">
            <Content>
              <div>
                {leftLinks.map((link) => (
                  <NavLink key={link.id} href={link.navigationItemLink}>
                    {link.navigationItemName}
                  </NavLink>
                ))}
              </div>
              <Link href="/" passHref>
                <StyledLogoLink className={scrolled ? 'scrolled' : ''}>
                  <Logo fill="currentColor" />
                </StyledLogoLink>
              </Link>
              <div>
                {rightLinks.map((link) => (
                  <NavLink key={link.id} href={link.navigationItemLink}>
                    {link.navigationItemName}
                  </NavLink>
                ))}
              </div>
            </Content>
          </Grid>
        </Container>
      </StickyHeader>
    </div>
  )
}

NavDesktop.propTypes = {
  navigationItems: arrayOf(shape()),
}

NavDesktop.defaultProps = {
  navigationItems: [],
}

export default NavDesktop
