import React from 'react'
import { arrayOf, shape } from 'prop-types'
import { Box, FlexContainer, FlexGrid } from 'components/layout'
import Text from 'components/text/Text'
import Apple from 'components/icons/Apple'
import Android from 'components/icons/Android'
import Instagram from 'components/icons/Instagram'
import Halo from './Halo'
import AppLink from './AppLink'
import FooterLink from './FooterLink'

const FooterMobile = ({ navigationItems }) => {
  const midIndex = Math.ceil(navigationItems.length / 2)
  const leftLinks = navigationItems.slice(0, midIndex)
  const rightLinks = navigationItems.slice(midIndex)

  return (
    <Box background="black" py="60px">
      <FlexContainer>
        <FlexGrid cols={12} alignContent="center">
          <Box display="flex" justifyContent="center">
            <Halo />
          </Box>
        </FlexGrid>
        <FlexGrid cols={12}>
          <Text
            pt="40px"
            color="white"
            fontSize="12px"
            fontWeight={400}
            lineHeight="26px"
          >
            DOWNLOAD THE BEATNIC REWARDS APP
          </Text>
          <Box pt="10px" pb="30px">
            <AppLink href="https://apps.apple.com/us/app/beatnic/id1473075569" size="30px">
              <Apple />
            </AppLink>
            <AppLink href="https://play.google.com/store/apps/details?id=com.bychefchloe.bychloe.levelup.whitelabel.app&hl=en_US&gl=US" size="30px">
              <Android />
            </AppLink>
          </Box>
        </FlexGrid>
        <FlexGrid cols={6}>
          {leftLinks.map((link) => (
            <FooterLink key={link.id} href={link.navigationItemLink}>
              {link.navigationItemName}
            </FooterLink>
          ))}
        </FlexGrid>
        <FlexGrid cols={6}>
          {rightLinks.map((link) => (
            <FooterLink key={link.id} href={link.navigationItemLink}>
              {link.navigationItemName}
            </FooterLink>
          ))}
        </FlexGrid>
        <FlexGrid cols={12}>
          <Box pt="20px">
            <AppLink href="/" size="24px">
              <Instagram />
            </AppLink>
            <Box pt="20px">
              <Text
                as="a"
                color="white"
                fontSize="12px"
                fontWeight={400}
                lineHeight="26px"
                href="mailto:hello@eatbeatnic.com"
                style={{ textDecoration: 'underline' }}
              >
                hello@eatbeatnic.com
              </Text>
            </Box>
          </Box>
          <Text
            pt="20px"
            color="white"
            fontSize="12px"
            fontWeight={400}
            lineHeight="17px"
          >
            &copy; 2021 BEATNIC – All Rights Reserved
          </Text>
          <Text
            color="silver"
            fontSize="12px"
            fontWeight={400}
            lineHeight="17px"
          >
            Branding By Pearlfisher
            <br />
            Design &amp; Site By Heat Waves
          </Text>
        </FlexGrid>
      </FlexContainer>
    </Box>
  )
}

FooterMobile.propTypes = {
  navigationItems: arrayOf(shape()),
}

FooterMobile.defaultProps = {
  navigationItems: [],
}

export default FooterMobile
