import React from 'react'
import {
  string, bool, node, func,
} from 'prop-types'
import styled from 'styled-components'
import theme from 'styles/theme'
import Portal from './Portal'

const Background = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${theme.zIndices.overlayBg};
`
const Wrapper = styled.div`
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: ${theme.zIndices.overlay};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  overflow: auto;
  width: 600px;
  max-height: calc(100% - 50px);
  max-width: calc(100% - 50px);
  padding: 20px;
  box-sizing: border-box;

  @media (min-width: ${theme.breakpoints.lg}) {
    padding: 40px;
  }
`

const Modal = ({
  open, id, children, onClose,
}) => (
  <Portal selector={id}>
    {open && (
    <>
      <Background onClick={onClose} />
      <Wrapper>
        {children}
      </Wrapper>
    </>
    )}
  </Portal>
)

Modal.propTypes = {
  id: string.isRequired,
  open: bool,
  onClose: func,
  children: node,
}

Modal.defaultProps = {
  open: false,
  children: null,
  onClose: () => {},
}

export default Modal
