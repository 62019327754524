import React from 'react'
import styled from 'styled-components'
import {
  string,
  number,
  array,
  oneOfType,
} from 'prop-types'
import {
  grid, layout, flexbox, position,
} from 'styled-system'
import Box from './Box'

const StyledGrid = styled(Box)`
  ${position}
  ${layout}
  ${grid}
  ${flexbox}
  ${({ alignSelf, placeSelf, justifySelf }) => `
    ${placeSelf ? `place-self: ${placeSelf};` : ''}
    ${alignSelf ? `align-self: ${alignSelf};` : ''}
    ${justifySelf ? `justify-self: ${justifySelf};` : ''}
  `}
`

const Grid = ({ col, row, ...other }) => (
  <StyledGrid
    {...other}
    gridColumn={col}
    gridRow={row}
  />
)

Grid.propTypes = {
  col: oneOfType([string, number, array]),
  row: oneOfType([string, number, array]),
  placeSelf: oneOfType([string, array]),
  alignSelf: oneOfType([string, array]),
}

Grid.defaultProps = {
  col: 1,
  row: 1,
  placeSelf: undefined,
  alignSelf: undefined,
}

export default Grid
