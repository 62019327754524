import React from 'react'
import styled from 'styled-components'
import { isArray } from 'lodash'
import {
  number,
  array,
  oneOfType,
} from 'prop-types'
import {
  layout, flexbox, position,
} from 'styled-system'
import theme from 'styles/theme'
import Box from './Box'

const StyledGrid = styled(Box)`
  ${layout}
  ${flexbox}
  ${position}
  ${({ alignSelf, placeSelf, justifySelf }) => `
    ${placeSelf ? `place-self: ${placeSelf};` : ''}
    ${alignSelf ? `align-self: ${alignSelf};` : ''}
    ${justifySelf ? `justify-self: ${justifySelf};` : ''}
  `}
`

const FlexGrid = ({ cols, ...other }) => {
  const sizes = isArray(cols) ? cols : [cols]
  const widths = sizes.map((col) => `${(col / 12) * 100}%`)
  const margins = Object.keys(theme.grid.gutters).map((key) => `${theme.grid.gutters[key].replace('px', '') * 0.5}px`)

  return (
    <StyledGrid width={widths} px={margins} {...other} />
  )
}

FlexGrid.propTypes = {
  cols: oneOfType([number, array]),
}

FlexGrid.defaultProps = {
  cols: 1,
}

export default FlexGrid
