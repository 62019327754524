import React from 'react'
import { node, string } from 'prop-types'
import Link from 'next/link'
import styled from 'styled-components'

const StyledLink = styled.a`
  text-decoration: none;
  outline: none;
  color: black;
  font-family: ${({ theme }) => theme.fonts.RocGrotesk};
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  padding-right: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    color: white;
  }
  
  &:last-child {
    padding-right: 0px;
  }
`

const NavLink = ({ href, children, ...other }) => {
  if (href.startsWith('/')) {
    return (
      <Link href={href} passHref>
        <StyledLink {...other}>
          {children}
        </StyledLink>
      </Link>
    )
  }
  return (
    <StyledLink href={href} {...other}>
      {children}
    </StyledLink>
  )
}

NavLink.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
}

export default NavLink
