import React from 'react'
import { useContentful } from 'react-contentful'
import { fieldsParser } from 'contentful-parsers'
import { isClient } from 'next-utils'
import useIsMobile from 'components/layout/useIsMobile'
import FooterDesktop from './FooterDesktop'
import FooterMobile from './FooterMobile'

const Footer = () => {
  const { data } = useContentful({
    contentType: 'footerModule',
    parser: (parserData) => (parserData && fieldsParser(parserData)) || null,
    id: '3ObnEeisjP0fAJPaGRtOQT',
    skip: !isClient(),
  })
  const isMobile = useIsMobile()

  const { navigationItems } = data || { navigationItems: [] }
  return isMobile
    ? <FooterMobile navigationItems={navigationItems} />
    : <FooterDesktop navigationItems={navigationItems} />
}

export default Footer
