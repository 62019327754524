import React from 'react'

const Android = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66351 15.5479C5.83647 15.5479 5.13476 15.8363 4.55794 16.4131C3.98112 16.9899 3.69263 17.6821 3.69263 18.4899V30.8937C3.69263 31.7218 3.98104 32.4229 4.55794 32.9998C5.13476 33.5766 5.83647 33.8651 6.66351 33.8651C7.48994 33.8651 8.1874 33.5767 8.75528 32.9998C9.32203 32.423 9.60618 31.7218 9.60618 30.8937V18.4899C9.60618 17.682 9.31777 16.9899 8.74087 16.4131C8.16396 15.8363 7.47137 15.5479 6.66351 15.5479Z" fill="white" />
    <path d="M30.5484 4.41308L32.5965 0.634862C32.731 0.384729 32.6832 0.192919 32.4526 0.0580444C32.2024 -0.0582565 32.0102 0.00067506 31.8758 0.230673L29.7991 4.03909C27.9717 3.23115 26.0389 2.82644 24.0008 2.82644C21.9622 2.82644 20.0293 3.23124 18.2026 4.03909L16.1257 0.230673C15.9908 0.00067506 15.7985 -0.0577358 15.5489 0.0580444C15.3179 0.193526 15.27 0.384729 15.405 0.634862L17.4532 4.41308C15.3764 5.4722 13.7223 6.94723 12.4916 8.84137C11.2609 10.7366 10.6453 12.8076 10.6453 15.0581H37.3273C37.3273 12.8081 36.7116 10.737 35.481 8.84137C34.2502 6.94723 32.6057 5.4722 30.5484 4.41308ZM18.7072 9.90856C18.4857 10.1306 18.2211 10.2411 17.9136 10.2411C17.6055 10.2411 17.3464 10.1306 17.1349 9.90856C16.9235 9.68759 16.8178 9.42409 16.8178 9.11554C16.8178 8.80804 16.9235 8.54402 17.1349 8.32253C17.3464 8.10156 17.6061 7.99107 17.9136 7.99107C18.2211 7.99107 18.4857 8.10156 18.7072 8.32253C18.9281 8.54454 19.0392 8.80804 19.0392 9.11554C19.0385 9.42357 18.9276 9.68759 18.7072 9.90856ZM30.8655 9.90856C30.6536 10.1306 30.3939 10.2411 30.0869 10.2411C29.7787 10.2411 29.5142 10.1306 29.2932 9.90856C29.0718 9.68759 28.9613 9.42409 28.9613 9.11554C28.9613 8.80804 29.0718 8.54402 29.2932 8.32253C29.5142 8.10156 29.7787 7.99107 30.0869 7.99107C30.3944 7.99107 30.6535 8.10156 30.8655 8.32253C31.0771 8.54454 31.1826 8.80804 31.1826 9.11554C31.1826 9.42357 31.077 9.68759 30.8655 9.90856Z" fill="white" />
    <path d="M10.7598 35.3074C10.7598 36.1929 11.0673 36.9424 11.6824 37.5575C12.298 38.1726 13.0475 38.4801 13.9324 38.4801H16.0671L16.0963 45.0289C16.0963 45.8558 16.3847 46.558 16.9616 47.1349C17.5384 47.7118 18.2311 48.0003 19.0384 48.0003C19.8649 48.0003 20.5671 47.7118 21.144 47.1349C21.7209 46.558 22.0093 45.8559 22.0093 45.0289V38.4807H25.9899V45.0289C25.9899 45.8558 26.2782 46.558 26.8551 47.1349C27.432 47.7118 28.1336 48.0003 28.9607 48.0003C29.7872 48.0003 30.4894 47.7118 31.0663 47.1349C31.6432 46.558 31.9315 45.8559 31.9315 45.0289V38.4807H34.095C34.9603 38.4807 35.7002 38.1731 36.3164 37.558C36.9314 36.9429 37.2391 36.1934 37.2391 35.308V16.0957H10.7598V35.3074Z" fill="white" />
    <path d="M41.3369 15.5479C40.529 15.5479 39.8369 15.832 39.26 16.3988C38.6832 16.9666 38.3948 17.664 38.3948 18.4899V30.8937C38.3948 31.7218 38.6831 32.4229 39.26 32.9998C39.8369 33.5767 40.5295 33.8651 41.3369 33.8651C42.1634 33.8651 42.8656 33.5767 43.4425 32.9998C44.0194 32.4229 44.3077 31.7218 44.3077 30.8937V18.4899C44.3077 17.6639 44.0194 16.9666 43.4425 16.3988C42.8656 15.832 42.1634 15.5479 41.3369 15.5479Z" fill="white" />
  </svg>
)

export default Android
