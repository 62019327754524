import React from 'react'
import { node, string } from 'prop-types'
import { Box } from 'components/layout'
import Link from 'components/links/Link'
import { Text } from 'components/text'

const Button = ({
  backgroundColor, children, href, icon, labelColor, ...rest
}) => (
  <Link href={href} textDecoration="none" {...rest}>
    <Box
      display="flex"
      alignItems="center"
      backgroundColor={backgroundColor}
      justifyContent="center"
      px="20px"
      py="10px"
    >
      {icon && (
        <Box mr="10px">{icon}</Box>
      )}
      <Text
        as="span"
        color={labelColor}
        fontSize={['14px', '18px', '20px']}
        lineHeight={['14px', '18px', '20px']}
        fontWeight="600"
        textTransform="uppercase"
      >
        {children}
      </Text>
    </Box>
  </Link>
)

Button.propTypes = {
  backgroundColor: string,
  children: node,
  href: string,
  icon: node,
  labelColor: string,
}

Button.defaultProps = {
  backgroundColor: 'white',
  children: null,
  href: null,
  icon: null,
  labelColor: 'black',
}

export default Button
